import { Link } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"

// markup
const AGB = () => {
  return (
    <Layout pageTitle="Informationen zum Benutzerkonto">
      <h4>Hinweis zum Löschen/Entfernen Ihres Benutzerkontos</h4>
      <p>Sollten Sie Ihr Benutzerkonto löschen wollen, gehen Sie bitte wie folgt vor:</p>
      <ol>
        <li>Melden Sie sich mit Ihrem Benutzerkonto in der App oder auf der Vorbestell-Webseite Ihrer Apotheke an.</li>
        <li>Rufen Sie das Menü auf (drei Striche oben links) und gehen Sie zu "Mein Benutzerkonto".</li>
        <li>Dort finden Sie dann "Mein Konto löschen". Klicken Sie auf den Button.</li>
        <li>Ihr Konto ist nun gelöscht.</li>
      </ol>
      <p>Sollten Sie sich über einen Login-Provider angemeldet haben (Apple, Facebook oder Google), dann können Sie dort "Apotheken Direkt" unter Ihren verwendeten Logins bzw. verwendeten Apps löschen.</p>
      <p><i>Hinweis:</i> Ihr Konto ist nun auf der gesamten Apotheken Direkt Plattform gelöscht. Sie haben keinen Zugang mehr zu Ihren Vorbestellungen, auch wenn Sie diese bei verschiedenen Apotheken getätigt haben.</p>
    </Layout>
  )
}

export default AGB
